import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getDatabase, ref, set } from "firebase/database";

const App = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBgACosKUqSLf96cfeqjLja8KjF-qM4Y_0",
    authDomain: "agente-d3851.firebaseapp.com",
    databaseURL: "https://agente-d3851-default-rtdb.firebaseio.com",
    projectId: "agente-d3851",
    storageBucket: "agente-d3851.firebasestorage.app",
    messagingSenderId: "602325293125",
    appId: "1:602325293125:web:0ae0eb8fcf5f5b9e2a6b2c",
  };

  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);
  const database = getDatabase(app);
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BF-vz_plFNLiZiylCsPT-3w1AOcCjJ3o9JkFE0oQ6p88UtwvWSLA2w5ZbeWL0QfwfWmXHjyctnmb5sLufnZzxRo",
      })
        .then((currentToken) => {
          if (currentToken) {
            set(ref(database, "users/malia"), {
              fcmtoken: currentToken,
            });
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  });

  return <h1>Hello world</h1>;
};

export default App;
